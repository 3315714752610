import {
  clearFeatureFlags,
  clearSaveFeatureFlagSuccess,
  fetchFeatureFlagByTermSuccess,
  fetchFeatureFlagsSuccess,
  setFeatureFlagToFalseOnFetchFailure,
  setFeatureFlagsLoadingFlag,
  updateFeatureFlagsSuccess,
} from "../actions/featureFlagActions"

import { FeatureFlag } from "../types/featureFlagTypes"
import { createReducer } from "@reduxjs/toolkit"
import { mapFeatureFlagToVariableName } from "../../helpers/utilityHelpers"

interface InitialFeatureFlagState {
  featureFlags: FeatureFlag[]
  isLoading: boolean
  isSubmitting: boolean
  updateFeatureFlagsSuccessMessage: string

  // Individual feature flag state (to be removed later)
  isDevCycleFFEnabled?: boolean
  isPredictiveDealsFFEnabled?: boolean
  isPredScoreThresholdFFEnabled?: boolean
}

export const initialState: InitialFeatureFlagState = {
  featureFlags: [],
  isLoading: true,
  isSubmitting: false,
  updateFeatureFlagsSuccessMessage: "",

  // Individual feature flag state (to be removed later)
  isDevCycleFFEnabled: undefined,
  isPredictiveDealsFFEnabled: undefined,
  isPredScoreThresholdFFEnabled: undefined,
}

export default createReducer(initialState, (builder) =>
  builder
    /*================================ FeatureFlag List action handlers ================================*/
    .addCase(setFeatureFlagsLoadingFlag, (state, action) => {
      state.isLoading = action.payload
    })
    .addCase(fetchFeatureFlagsSuccess, (state, action) => {
      state.featureFlags = action.payload.featureFlags
    })
    .addCase(fetchFeatureFlagByTermSuccess, (state, action) => {
      const { name, value } = action.payload.featureFlag
      state[mapFeatureFlagToVariableName(name)] = value
    })
    .addCase(setFeatureFlagToFalseOnFetchFailure, (state, action) => {
      state[mapFeatureFlagToVariableName(action.payload)] = false
    })
    .addCase(updateFeatureFlagsSuccess, (state, action) => {
      state.updateFeatureFlagsSuccessMessage = action.payload
    })
    .addCase(clearSaveFeatureFlagSuccess, (state) => {
      state.updateFeatureFlagsSuccessMessage = initialState.updateFeatureFlagsSuccessMessage
    })
    .addCase(clearFeatureFlags, () => initialState)
)
